﻿import * as moment from 'moment';
import 'daterangepicker';

var ResultViewModel = function () {
    var self = this;
    self.FilteredProducts = ko.observableArray();
    self.Products = ko.observableArray();
    self.Categories = ko.observableArray([]);
    self.Destinations = ko.observableArray([
        { name: "Entire Ukraine", id: 781 },
        { name: "Kyiv", id: 22377 },
        { name: "Lviv", id: 26299 },
        { name: "Odessa", id: 4212 },
        { name: "Cherkasy", id: 4841 }
    ]);
    self.Text = ko.observable();
    self.StartDate = ko.observable();
    self.EndDate = ko.observable();
    self.CatId = ko.observable(0);
    self.SubCatId = ko.observable(0);
    self.Destination = ko.observable("Entire Ukraine");
    self.DestId = ko.observable();
    self.MinPrice = ko.observable(0);
    self.MaxPrice = ko.observable(500);
    self.showSpinner = ko.observable(false);
    self.ResultsExist = ko.observable(false);
    self.NeedToShowSeeMoreResultButton = ko.observable(false);
    self.toggleSpinner = function () {
        self.showSpinner(!self.showSpinner());
    };
    self.countOfShowProducts;

    $(function () {
        var start = moment().add(1, 'day').endOf('day');
        var end = moment().add(1, 'day').endOf('day');
        if (self.StartDate() !== undefined && self.EndDate() !== undefined) {
            start = moment(self.StartDate(), "YYYY-MM-DD");
            end = moment(self.EndDate(), "YYYY-MM-DD");
        }
        function cb(start, end) {
            $('#reportrange span').html(start.format('ddd D MMM') + ' - ' + end.format('ddd D MMM'));
            self.StartDate(start.format('YYYY-M-D'));
            self.EndDate(end.format('YYYY-M-D'));
        }

        $.fn.onStartDateChosen = function (cb) {
            var t, el;
            $(this).on('show.daterangepicker', function (ev, picker) {
                var sd = $('td.start-date').data('title');
                t = setInterval(() => {
                    if (sd == $('td.start-date').data('title')) return;
                    sd = $('td.start-date').data('title');
                    if (!el || el !== sd) {
                        el = sd;
                        cb(ev, picker);
                    }
                }, 10);
            });
        };

        $("#reportrange").on('hide.daterangepicker', function (ev, picker) {
            if (self.StartDate() !== undefined && self.EndDate() !== undefined) {
                picker.startDate = moment(self.StartDate()).clone();
                picker.endDate = moment(self.EndDate()).clone();
            }
        });

        $("#reportrange").on('show.daterangepicker', function () {
            $(".drp-buttons .drp-selected").hide();
        });

        $('#reportrange').daterangepicker({
            autoUpdateInput: false,
            startDate: start,
            endDate: end
        }, cb).onStartDateChosen(function (ev, picker) {
            picker.applyButtonClasses = "applyBtn btn btn-sm btn-primary";
            cb(picker.startDate, picker.startDate);
            $('.applyBtn').prop("disabled", false);
        });

        cb(start, end);

    });



    self.getResult = function () {   
        self.ResultsExist(false);
        self.showSpinner(true);
        self.FilteredProducts.splice(0);
        self.Products.splice(0);
        self.countOfShowProducts = 10;
        var result = {
            StartDate: self.StartDate,
            EndDate: self.EndDate,
            Text: self.Text(),
            DestId: self.DestId(),
            DestName: self.Destination,
            CatId: self.CatId(),
            SubCatId: self.SubCatId(),
            MaxPrice: self.MaxPrice,
            MinPrice: self.MinPrice
        };
         
        $.ajax({
            type: 'POST',
            url: window.AppData.ApiPrefix + '/Viator/GetProducts/',
            dataType: "json",
            async: true,
            data: result,
            success: function (data) {
                if (self.DestId() === null)
                    self.Categories.splice(0);
                
                if (data !== null) {
                    data.TopProducts.forEach(function (element) {
                        self.Products.push(new ProductViewModel(self, element));
                    });
                    self.FilteredProducts(self.Products.slice(0, self.countOfShowProducts));
                    self.Products().forEach(function (element) {
                        initializeRating(element.Rating());
                    });
                    self.NeedToShowSeeMoreResultButton(self.Products().length > 0 && self.FilteredProducts().length < self.Products().length);
                    self.getCategories();
                }
                self.showSpinner(false);
                self.ResultsExist(true);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                if (xhr.status !== 403)
                    alert("Status: " + xhr.status + ", Error: " + thrownError, "Error");
                self.showSpinner(false);
            }
        });
    
    };
    self.getURL = function () {
        var link = "en/events?";
        if (self.DestId() !== undefined) {
            link += "DestId=" + self.DestId() + "&";
        }
        if (self.Text() !== undefined) {
            link += "Text=" + self.Text() + "&";
        }
        if (self.StartDate !== undefined) {
            link += "StartDate=" + self.StartDate() + "&";
        }
        if (self.EndDate !== undefined) {
            link += "EndDate=" + self.EndDate();
        }
        window.location = link;
    };

    self.getNextProducts = function () {
        self.countOfShowProducts += 5;
        self.FilteredProducts(self.Products.slice(0, self.countOfShowProducts));
        self.FilteredProducts().forEach(function (element) {
            initializeRating(element.Rating());
        });
        self.NeedToShowSeeMoreResultButton(self.Products().length > 0 && self.FilteredProducts().length < self.Products().length);
    };

    self.getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    self.doSearch = function doSearch() {
        if (window.location.href.includes("events") )
        {
            self.DestId(self.getUrlParameter('DestId'));
            self.Text(self.getUrlParameter('Text'));
            self.StartDate(self.getUrlParameter('StartDate'));
            self.EndDate(self.getUrlParameter('EndDate'));
            if (self.DestId() !== undefined) {
                var selectemItem = ko.utils.arrayFirst(self.Destinations(), function (i) {
                    return i.id == self.DestId();
                });
                self.Destination(selectemItem.name);
                self.getResult();
                self.showSpinner(false);
            }
        }
    };
    self.doSearch();
    self.getCategories = function () {
        $.ajax({
            type: 'GET',
            url: window.AppData.ApiPrefix + '/Viator/GetCategories/'+self.DestId(),
            dataType: "json",
            success: function (data) {
                if (data !== null) {
                    data.forEach(function (element) {
                        self.Categories.push(new CategoryViewModel(self, element));
                    });
                }
            },
            error: function (xhr, ajaxOptions, thrownError) {
                if (xhr.status !== 403)
                    alert("Status: " + xhr.status + ", Error: " + thrownError, "Error");
            }
        });
    };  

    self.MaxPrice.subscribe(self.getResult);
    self.MinPrice.subscribe(self.getResult);


    function initializeRating(rating) {
        $(".rating-" + rating + "").starRating({
            strokeColor: '#894A00',
            strokeWidth: 14,
            starSize: 14,
            readOnly: true
        });
    }
};

var ProductViewModel = function (owner, data) {
    var self = this;

    self.Owner = ko.observable(null);
    if (typeof owner !== "undefined") {
        self.Owner(owner);
    }

    ko.mapping.fromJS(data, {}, self);
};

var CategoryViewModel = function (owner, data) {
    var self = this;

    self.Owner = ko.observable(null);
    if (typeof owner !== "undefined") {
        self.Owner(owner);
    }
    ko.mapping.fromJS(data, {}, self);
};

export { ResultViewModel, ProductViewModel, CategoryViewModel }