﻿$(document).on("click", ".subscribe", function () {
    $.ajax({
        type: 'GET',
        url: '../../../en/subscribe/' + $("#subscribeemail").val(),
        async: true,
        success: function (data) {
            alert(data);
        },
        error: function (xhr, ajaxOptions, thrownError) {
            alert("Error!");
        }
    });

});