﻿import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

import 'lazysizes';

import '_js/modules/footer.js'


//var picHeight,
//    picWidth;

if (!window.AppData) {
    global.AppData = {};
}
global.AppData.ApiPrefix = "/en";